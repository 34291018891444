import { createApp } from 'vue'
// import { createStore } from 'vuex'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import debounce from './debouncer'
import router from './router'
import "./styles/style.scss";
import "./fonts/fontawesome/css/all.min.css"

const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.directive('debounce', (el, binding) => debounce(el, binding))
app.mount('#app')
