<template :keydown.tab='handleTab'>
    <!-- <ul class="skip-links">
        <li>
            <a href="#main" ref="skipLink">Skip to main content</a>
        </li>
    </ul> -->
    <skip-links />
    <navbar :showNavbar="showNavbar" />

    <div id="main-content">
        <router-view v-slot="{ Component, route }">
            <transition name="fade">
                <component :is="Component"
                           :key="route.path" />
            </transition>
        </router-view>
    </div>

    <modal-options v-if="activeModal === 'options'" />
    <modal-player-mode v-if="activeModal === 'playerMode'" />
</template>

<script>
import axios from "axios";
import SkipLinks from "./components/skipLinks.vue";
import Navbar from "@/components/Navbar.vue";
import ModalOptions from "./components/ModalOptions.vue";
import ModalPlayerMode from "./components/ModalPlayerMode.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "App",
    components: {
        Navbar,
        SkipLinks,
        ModalOptions,
        ModalPlayerMode,
    },
    data() {
        return {
            showNavbar: true,
        };
    },
    mounted() {
        document.body.classList.remove("loading");
        this.getLocalStorage();
        this.getEpisodeList();
        this.checkScanlines();
    },
    computed: {
        ...mapGetters([
            "activeModal",
            "paused",
            "showScanlines",
            "contrastMode",
            "episodeNumber",
            "currentPanel",
        ]),

        routeName() {
            return this.$route.name;
        },
        isFullScreen() {
            return (
                window.innerHeight == screen.height ||
                document.fullscreenElement
            );
        },
    },
    watch: {
        routeName(newRoute) {
            if (newRoute == "Read") {
                this.showNavbar = false;
                // this.setFullScreen(true);
            }
            else {
                this.showNavbar = true;
                this.setFullScreen(false);
            }
            this.checkScanlines();
        },
        showScanlines() {
            this.checkScanlines();
        },
        contrastMode() {
            this.checkContrastMode();
        },
        paused() {
            this.checkScanlines();
        },
        episodeNumber() {
            localStorage.episodeNumber = this.episodeNumber;
        },
        currentPanel() {
            localStorage.currentPanel = this.currentPanel;
        },
    },
    methods: {
        ...mapMutations([
            "setEpisodeNumber",
            "setEpisodePanel",
            "setMovieMode",
            "setReadingSpeed",
            "setScanlines",
            "setContrastMode",
        ]),

        setFullScreen(switchOn) {
            if (switchOn) {
                document.documentElement.requestFullscreen();
            } else if (this.isFullScreen)
                document.exitFullscreen();
        },
        getLocalStorage() {
            if (localStorage.currentPanel) {
                this.setEpisodePanel(localStorage.currentPanel);
            }
            if (localStorage.episodeNumber) {
                this.setEpisodeNumber(localStorage.episodeNumber);
            }
            if (localStorage.movieMode) {
                this.setMovieMode(localStorage.movieMode);
            }
            if (localStorage.readingSpeed) {
                this.setReadingSpeed(localStorage.readingSpeed);
            }
            if (localStorage.scanlines) {
                this.setScanlines(localStorage.scanlines);
            }
            if (localStorage.contrastMode) {
                this.setContrastMode(localStorage.contrastMode);
            }
            this.checkScanlines();
            this.checkContrastMode();
        },
        async getEpisodeList() {
            try {
                const response = await axios.get("/episodes/episodeList.json?nocache=" + (new Date()).getTime());
                this.$store.commit("episodeList", response.data.episodes);
                this.$store.commit("latestUpdate", response.data.latestUpdate);
            } catch (error) {
                console.log(error.toJSON());
            }
        },
        beginComic(episodeNum) {
            this.$store.dispatch("beginEpisode", episodeNum);
        },
        checkScanlines() {
            if (this.showScanlines == true) {
                if (this.routeName == "Editor")
                    document.body.classList.remove("scanlines");
                else document.body.classList.add("scanlines");
            } else {
                document.body.classList.remove("scanlines");
            }
        },
        checkContrastMode() {
            if (this.contrastMode == true) {
                if (this.routeName == "Editor")
                    document.body.classList.remove("high-contrast");
                else document.body.classList.add("high-contrast");
            } else {
                document.body.classList.remove("high-contrast");
            }
        },
        getTimecode(millisec) {
            var seconds = (millisec / 1000).toFixed(0);
            var minutes = Math.floor(seconds / 60);
            var hours = "";
            if (minutes > 59) {
                hours = Math.floor(minutes / 60);
                hours = hours >= 10 ? hours : "0" + hours;
                minutes = minutes - hours * 60;
                minutes = minutes >= 10 ? minutes : "0" + minutes;
            }

            seconds = Math.floor(seconds % 60);
            seconds = seconds >= 10 ? seconds : "0" + seconds;
            if (hours !== "") {
                return hours + ":" + minutes + ":" + seconds;
            }
            return minutes + ":" + seconds;
        },

    },
};
</script>

