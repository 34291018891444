import { createStore } from 'vuex'
import axios from "axios"

const state = {
    // root state object.
    // each Vuex instance is just a single state tree.

    episodeList: [],
    latestUpdate: 0,

    episode: {
        title: '',
        description: '',
        number: 0,
        sceneCount: 1,
        panelCount: 1,
        data: [{ layers: [], dialog: [], minDuration: 1, transition: { type: "none", duration: 1 } }]
    },
    editMode: false,
    paused: false,
    currentEpisode: 0,
    maxKeyframes: 2,
    currentKeyframe: 0,
    currentPanel: 0,
    currentScene: 0,
    saved: true, // is edit state dirty

    // User Options
    activeModal: false,
    showScanlines: false,
    contrastMode: false,
    movieMode: false, // false = click to advance
    readingSpeed: 15, // words per min
}

const getters = {
    // getters are functions.

    episodeList: state => state.episodeList,
    latestUpdate: state => state.latestUpdate,

    episode: state => state.episode,
    panelCount: state => state.episode.panelCount,
    sceneCount: state => state.episode.sceneCount,
    episodeTitle: state => state.episode.title,
    episodeDescription: state => state.episode.description,
    episodeNumber: state => state.currentEpisode,
    // episodeNumber: state => state.episode.number,
    episodeData: state => state.episode.data,

    editMode: state => state.editMode,
    paused: state => state.paused,
    maxKeyframes: state => state.maxKeyframes,
    currentKeyframe: state => state.currentKeyframe,
    currentPanel: state => state.currentPanel,
    currentScene: state => state.currentScene,
    saved: state => state.saved,

    activeModal: state => state.activeModal,
    showScanlines: state => state.showScanlines,
    contrastMode: state => state.contrastMode,
    movieMode: state => state.movieMode,
    readingSpeed: state => state.readingSpeed,
}

const mutations = {
    // mutations are operations that actually mutate the state.
    // each mutation handler gets the entire state tree as the
    // first argument, followed by additional payload arguments.
    // mutations must be synchronous and can be recorded by plugins
    // for debugging purposes.

    episodeList(state, payload) {
        state.episodeList = payload;
    },
    latestUpdate(state, payload) {
        state.latestUpdate = payload;
    },
    setEditMode(state, bool) {
        // False = playing, true = editing
        // console.log('editMode', bool)
        state.editMode = bool;
    },
    setPaused(state, bool) {
        // console.log('setPaused', bool)
        state.paused = bool;
    },
    setCurrentKeyframe(state, num) {
        // console.log('currentKeyframe', num)
        state.currentKeyframe = parseInt(num);
    },
    goFirstKeyframe(state) {
        state.currentKeyframe = 0;
    },
    goNextKeyframe(state) {
        if (state.currentKeyframe < state.maxKeyframes - 1)
            state.currentKeyframe++;
    },
    goLastKeyframe(state) {
        state.currentKeyframe = parseInt(state.maxKeyframes) - 1;
    },
    setEpisode(state, payload) {
        // console.log('store setEpisode')
        if (payload && payload.sceneCount)
            state.episode = payload;
        else {
            alert("Not a valid episode file")
            console.warn('setEpisode file', payload)
        }
    },
    episodeData(state, payload) {
        state.episode.data = payload;
        state.currentEpisode = payload.number;
    },
    setEpisodeNumber(state, num) {
        state.currentEpisode = parseInt(num);
        localStorage.episodeNumber = parseInt(num);
    },
    setEpisodeScene(state, num) {
        state.currentPanel = parseInt(num);
    },
    setEpisodePanel(state, num) {
        state.currentPanel = parseInt(num);
        localStorage.currentPanel = parseInt(num);
    },
    saved(state, payload) {
        state.saved = payload;
    },

    goFirstPanel(state) {
        // console.log('s-- goFirstPanel', 0)
        state.currentPanel = 0;
    },
    goPrevPanel(state) {
        // console.log('s-- goPrevPanel', state.currentPanel - 1)
        if (state.currentPanel > 0) {
            state.currentPanel = parseInt(state.currentPanel) - 1;
        }
    },
    goNextPanel(state) {
        if (state.currentPanel < state.episode.panelCount - 1) {
            // console.log('s-- goNextPanel GO', state.currentPanel + 1)
            state.currentPanel = parseInt(state.currentPanel) + 1;
        } else {
            // console.log('s-- goNextPanel NO SUCH PANEL', state.currentPanel + 1)
            state.editMode = true;
        }
    },
    goToPanel(state, num) {
        // console.log('currentPanel', num)
        state.currentPanel = parseInt(num);
    },
    goLastPanel(state) {
        // console.log('s-- goLastPanel', state.episode.panelCount - 1)
        state.currentPanel = parseInt(state.episode.panelCount) - 1;
    },
    updateLayerKeyframe(state, payload) {
        // console.log('>>store:payload', payload);
        const p = state.currentPanel;
        const l = payload.layer; // index of selected layer
        const f = payload.keyframe; // 0 or 1, for now
        const prop = payload.id; // ex: 'x' or 'blur'
        state.episode.data[p].layers[l].frame[f][prop] = payload.val;
    },
    updateMinDuration(state, n) {
        const p = state.currentPanel;
        state.episode.data[p].minDuration = n;
    },
    updateTransitionDuration(state, n) {
        const p = state.currentPanel;
        if (!state.episode.data[p].transition) {
            state.episode.data[p].transition = { type: 'none', duration: n }
        }
        else state.episode.data[p].transition.duration = n;
    },
    updateTransitionType(state, str) {
        const p = state.currentPanel;
        if (!state.episode.data[p].transition) {
            state.episode.data[p].transition = { type: str, duration: 1 }
        }
        else state.episode.data[p].transition.type = str;
    },
    showModal(state, modalId) {
        // name of modal, only 1 is shown at a time
        state.activeModal = modalId;
    },
    closeModal(state) {
        state.activeModal = false;
    },
    setScanlines(state, bool) {
        let val = (bool === "true" || bool === true)
        state.showScanlines = val;
    },
    setContrastMode(state, bool) {
        let val = (bool === "true" || bool === true)
        state.contrastMode = val;
    },
    setMovieMode(state, bool) {
        let val = (bool === "true" || bool === true)
        state.movieMode = val;
    },
    setReadingSpeed(state, num) {
        state.readingSpeed = parseInt(num);
    },

}

const actions = {
    // actions are functions that cause side effects and can involve
    // asynchronous operations.

    createBlankEpisode({ commit }) {
        // var data = [{ layers: [], dialog: [] }];
        // commit('episodeData', data);
        // console.log('store createBlankEpisode')
        const blank = {
            title: 'Untitled',
            number: 1,
            sceneCount: 1,
            panelCount: 1,
            data: [{ layers: [], dialog: [], minDuration: 1, transition: { type: "none", duration: 1 } }]
        }
        commit('setEpisode', blank);
    },

    async loadEpisode({ commit }, payload) {
        // console.log("store.loadEpisode", payload.episode, payload.panel);
        try {
            const url = '/episodes/' + payload.episode + '/' + payload.episode + '.json?nocache=' + (new Date()).getTime();
            // console.log(url)
            const data = await axios.get(url)
            commit('setEpisode', data.data);
            commit('setEpisodeNumber', payload.episode);
            commit('setEpisodePanel', payload.panel);
            // console.log("store.loadEpisode", data.data);
            return true
        }
        catch (error) {
            console.log(error)
            return false
        }
    },
    // TODO: load episode data
    // see async actions
    //  https://vuex.vuejs.org/guide/actions.html#composing-actions

    importEpisode({ commit }, payload) {
        // console.log("store.importEpisode", payload);
        commit('episode', payload);
        // TODO: load episode data
        // see async actions
        //  https://vuex.vuejs.org/guide/actions.html#composing-actions
    }

    // checkout({ commit, state }, products) {
    //     // save the items currently in the cart
    //     const savedCartItems = [...state.cart.added]
    //     // send out checkout request, and optimistically
    //     // clear the cart
    //     commit(types.CHECKOUT_REQUEST)
    //     // the shop API accepts a success callback and a failure callback
    //     shop.buyProducts(
    //         products,
    //         // handle success
    //         () => commit(types.CHECKOUT_SUCCESS),
    //         // handle failure
    //         () => commit(types.CHECKOUT_FAILURE, savedCartItems)
    //     )
    // }
}

export default createStore({
    // A Vuex instance is created by combining the state, 
    // mutations, actions, and getters.
    state,
    getters,
    actions,
    mutations
})

// load json from 'public' folder
// fetch('/episodes/episodeList.json').then(response => {
//     // console.log(response);
//     return response.json();
//   }).then(data => {
//     // console.log(data);
//     commit('episodeList', data.episodes);
//   }).catch(err => {
//     console.log("Error Reading data:", err);
//   });