import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/read',
    name: 'Read',
    component: () => import('../views/Read.vue')
  },
  {
    path: '/edit',
    name: 'Editor',
    component: () => import('../views/Editor.vue')
  },

  // About sub-pages
  {
    path: '/about/jaar',
    name: 'Jaar',
    component: () => import('../views/characters/jaar.vue')
  },
  {
    path: '/about/roto',
    name: 'Roto',
    component: () => import('../views/characters/roto.vue')
  },
  {
    path: '/about/lylah',
    name: 'Lylah',
    component: () => import('../views/characters/lylah.vue')
  },
  {
    path: '/about/dadelik',
    name: 'Dadelik',
    component: () => import('../views/characters/dadelik.vue')
  },
  {
    path: '/about/huis',
    name: 'Huis',
    component: () => import('../views/characters/huis.vue')
  },
  {
    path: '/about/kua',
    name: 'Kua',
    component: () => import('../views/characters/kua.vue')
  },
  {
    path: '/about/ochin',
    name: 'Ochin',
    component: () => import('../views/characters/ochin.vue')
  },
  {
    path: '/about/ollyhicks',
    name: 'OllyHicks',
    component: () => import('../views/characters/ollyhicks.vue')
  },
  {
    path: '/about/episode-0',
    name: 'Episode0',
    component: () => import('../views/characters/episode0.vue')
  },
  {
    path: '/about/episode-1',
    name: 'Episode1',
    component: () => import('../views/characters/episode1.vue')
  },

  // 404 error all other pages
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 60
      }
    } else {
      // return { x: 0, y: 0 };}
      window.scrollTo(0, 0);
    }
  }
})

export default router
