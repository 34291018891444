<template>
    <nav
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="{
            'fade-out': !showNavbar && !paused,
            'hover-show-nav': hover,
        }"
        class="navbar is-fixed-top is-black"
        role="navigation"
        aria-label="main navigation"
    >
        <div class="navbar-brand">
            <router-link title="Home" class="navbar-item" to="/">
                <img src="@/assets/img/favicon.png" width="24" />
            </router-link>

            <a
                role="button"
                class="navbar-burger"
                :class="{ 'is-active': showMobileNav }"
                @click="showMobileNav = !showMobileNav"
                @keyup.enter="showMobileNav = !showMobileNav"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarMenu"
                tabindex="0"
            >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div
            id="navbarMenu"
            class="navbar-menu"
            :class="{ 'is-active': showMobileNav }"
        >
            <div class="navbar-start">
                <a
                    @click="startEpisode(0)"
                    @keyup.enter="startEpisode(0)"
                    title="Start at the beginning"
                    class="navbar-item"
                    tabindex="0"
                    >START</a
                >
                <a
                    v-if="showResume"
                    @click="resumeEpisode"
                    @keyup.enter="resumeEpisode"
                    :title="'Continue reading episode #' + episodeNumber"
                    class="navbar-item"
                    tabindex="0"
                    >Resume</a
                >
                <a
                    @click="latestEpisode"
                    @keyup.enter="latestEpisode"
                    :title="'Read the latest update'"
                    class="navbar-item"
                    tabindex="0"
                    >Latest update</a
                >
                <router-link
                    v-if="showEditButton"
                    :to="{ name: 'Editor' }"
                    class="navbar-item has-text-danger"
                    >Editor
                </router-link>
            </div>

            <div class="navbar-end">
                <div class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link" tabindex="0">Episodes</a>

                    <div
                        v-if="episodeList.length < 1"
                        class="navbar-dropdown is-right"
                    >
                        <a class="navbar-item">(none)</a>
                    </div>

                    <div v-else class="navbar-dropdown is-right">
                        <a
                            v-for="episode in episodeList"
                            :key="episode.number"
                            @click="startEpisode(episode.number)"
                            @keyup.enter="startEpisode(episode.number)"
                            class="navbar-item"
                            tabindex="0"
                            >{{ episode.number }}. {{ episode.title }}</a
                        >
                    </div>
                </div>
                <a
                    @click="showOptions"
                    @keyup.enter="showOptions"
                    class="navbar-item"
                    tabindex="0"
                    >Options</a
                >
                <router-link class="navbar-item" :to="{ name: 'About' }"
                    >About
                </router-link>
                <a class="navbar-item" href="rss.xml" target="_blank" title="Copy this link into your RSS reader"
                    ><i class="fa-solid fa-rss"></i
                ></a>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "Navbar",
    components: {},
    props: ["showNavbar"],
    data() {
        return {
            showMobileNav: false,
            hover: false,
        };
    },

    mounted() {},
    computed: {
        ...mapGetters([
            "episodeNumber",
            "currentPanel",
            "episodeList",
            "latestUpdate",
            "paused",
        ]),

        routeName() {
            return this.$route.name;
        },
        showResume() {
            if (this.routeName == "Editor") return true;
            if (this.routeName == "Read") return false;
            if (this.episodeNumber && this.currentPanel > 0) return true;
            if (localStorage.episodeNumber && localStorage.currentPanel)
                return true;
            return false;
        },
        showEditButton() {
            if (
                process.env.NODE_ENV === "development" ||
                window.location.hostname === "localhost"
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        routeName() {
            this.showMobileNav = false;
            document.activeElement.blur();
        },
    },
    methods: {
        ...mapMutations([
            "setEpisodeNumber",
            "setEpisodePanel",
            "setReadingSpeed",
            "setScanlines",
            "setPaused",
            "showModal",
        ]),
        startEpisode(num) {
            // console.log("startEpisode Navbar", num);
            document.activeElement.blur();
            if (!this.showResume && this.routeName != "Read") {
                this.setEpisodeNumber(num);
                this.showModal("playerMode");
            } else {
                this.setPaused(false);
                this.$store.dispatch("loadEpisode", { episode: num, panel: 0 });
                this.$router.push({
                    name: "Read",
                    query: { episode: num },
                });
            }
        },
        resumeEpisode() {
            if (this.routeName !== "Read") {
                document.activeElement.blur();
                const num = this.episodeNumber ? this.episodeNumber : 0;
                const panel = this.currentPanel ? this.currentPanel : 0;
                // loadEpisode needed to refresh "read" page
                this.$store.dispatch("loadEpisode", {
                    episode: num,
                    panel: panel,
                });
                this.setPaused(true);
                this.$router.push({
                    name: "Read",
                    query: {
                        episode: num + "." + panel,
                    },
                });
                setTimeout(() => {
                    this.setPaused(false);
                }, 100);
            }
        },
        latestEpisode() {
            const num =
                this.latestUpdate.episode + "." + this.latestUpdate.panel;
            this.setPaused(false);
            this.$store.dispatch("loadEpisode", this.latestUpdate);
            this.$router.push({
                name: "Read",
                query: { episode: num },
            });
        },
        showOptions() {
            document.activeElement.blur();
            if (this.routeName == "Read") {
                this.setPaused(true);
            }
            this.showModal("options");
        },
    },
};
</script>