<template>
    <div class="modal is-active">
        <div @click="close" class="modal-background">
            <!-- <div class="code">{{ code }}</div> -->
        </div>
        <div class="modal-card" role="dialog">
            <header class="modal-card-head">
                <p class="modal-card-title">Before you start</p>
                <button @click="close" class="delete" aria-label="close"></button>
            </header>

            <section class="modal-card-body">
                <div class="has-text-centered">Choose your playback mode</div>
                <div class="modal-boxes">
                    <a @click="setMode(false)" class="modal-box">
                        <figure class="image is-64x64">
                            <img class="icon is-pixels" src="@/assets/img/icon-book.png" />
                        </figure>
                        <div>Reader Mode</div>
                        <p>Click to advance</p>
                    </a>
                    <a @click="setMode(true)" class="modal-box">
                        <figure class="image is-64x64">
                            <img class="icon is-pixels" src="@/assets/img/icon-movie.png" />
                        </figure>
                        <div>Movie Mode</div>
                        <p>Sit back and watch</p>
                    </a>
                </div>
                <p class="has-text-centered">
                    You can change this anytime in the options
                </p>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// TODO: Trap tab focus to modal
// see https://hidde.blog/using-javascript-to-trap-focus-in-an-element/

export default {
    name: "ModalPlayerMode",
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["episodeNumber"]),
        // routeName() {
        //     return this.$route.name;
        // },
    },
    watch: {},
    methods: {
        ...mapMutations(["closeModal", "setPaused"]),
        close() {
            this.closeModal();
        },
        setMode(value) {
            localStorage.movieMode = value;
            this.$store.commit("setMovieMode", value);
            this.closeModal();

            this.setPaused(false);
            const num = this.episodeNumber ? this.episodeNumber : 0;
            this.$store.dispatch("loadEpisode", { episode: num, panel: 0 });
            this.$router.push({
                name: "Read",
                query: { episode: num },
            });
        },
    },
};
</script>