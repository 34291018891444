<template>
    <div id="home-screen">
        <div class="map-container">
            <div class="map pixels">
                <div class="water" />
                <!-- <div class="marker episode0">(1)</div> -->
                <!-- <div class="marker episode1">(2)</div> -->
            </div>
        </div>
        <div class="cast-container">
            <div class="back-right" />
            <div class="cast" />
            <div class="front-center" />
            <div class="front-left" />
        </div>
    </div>
</template>

<script>
export default {
    name: "WorldMap",
    props: {
        // msg: String
    },
};
</script>

<style scoped lang="scss">
#home-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    z-index: -1;
    overflow: hidden;
}

.map-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    perspective: 800px;
    background: linear-gradient(#3e99c3, #6eb462, #bdb9a5);

    .map {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        mask-image: linear-gradient(black 70%, transparent 100%);
        background: url(../assets/img/bg-1bit-trash-icons.png) center;
        background-position-y: 0px;
        background-size: 600px 600px;
        transform-style: preserve-3d;
        transform: translateY(0%) rotate3d(1, 0, 0, 90deg) scale(2);
        // animation: openMap 3s 0.8s ease-out forwards;
        animation: openMap 3s 0.8s ease-out forwards,
            movePixels 350s linear infinite;

        .water {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: 2 / 1;
            background: url(../assets/img/map.gif) center no-repeat;
            background-size: cover;
        }

        .marker {
            font-family: cursive;
            display: block;
            position: absolute;
            font-size: 0.5rem;
            opacity: 0.5;
            color: rgb(94, 19, 19);
            transform-style: preserve-3d;
            transform: rotate3d(1, 0, 0, 90deg);
            animation: openMapMarkers 3s 0.8s ease-out forwards;
        }

        .episode0 {
            top: 70%;
            left: 60%;
        }

        .episode1 {
            top: 72%;
            left: 55%;
        }
    }
}

@keyframes openMap {
    0% {
        transform: translateY(0%) rotate3d(1, 0, 0, 90deg) scale(2);
        opacity: 0;
    }

    33% {
        opacity: 1;
    }

    100% {
        transform: translateY(-33%) rotate3d(1, 0, 0, 0deg) scale(1);
        opacity: 1;
    }
}

@keyframes movePixels {
    0% {
        background-position-y: 1600px;
    }

    100% {
        background-position-y: 0px;
    }
}

@keyframes openMapMarkers {
    0% {
        transform: rotate3d(1, 0, 0, 90deg);
    }

    100% {
        transform: rotate3d(1, 0, 0, 0deg);
    }
}

.cast-container {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 66vh;
}

.cast,
.front-center,
.front-left,
.back-right {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    animation: showCast 2s 0.8s ease-out forwards;
}

.cast {
    height: 75%;
    background: url(../assets/img/cast-lineup.png) bottom center no-repeat;
    background-size: contain;
}

@keyframes showCast {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

.front-center {
    background: url(../assets/img/cast-obj-center1.png) center bottom repeat-x;
    background-size: contain;
}

.front-left {
    left: 0;
    background: url(../assets/img/cast-obj-front-left1.png) left bottom
        no-repeat;
    background-size: contain;
}

.back-right {
    height: 66%;
    right: 0;
    background: url(../assets/img/cast-obj-back1.png) right bottom no-repeat;
    background-size: contain;
}
</style>
