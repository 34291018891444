<template>
    <div class="modal is-active">
        <div @click="close"
             class="modal-background">
            <div class="code">{{ code }}</div>
        </div>
        <div class="modal-card"
             role="dialog">
            <header class="modal-card-head">
                <p class="modal-card-title">Options</p>
                <button @click="close"
                        class="delete"
                        aria-label="close"></button>
            </header>

            <section class="modal-card-body">
                <div class="field is-flex is-justify-content-space-between"
                     :class="{ 'is-ghost': !movieMode }">
                    <label>Reading speed: {{ readingSpeed }} ({{
                            readingFeedback
                    }})</label>
                    <input @mouseup="codeReadingSpeed"
                           @click="movieMode = true"
                           v-model="readingSpeed"
                           type="range"
                           min="1"
                           max="30" />
                </div>
                <div class="field">
                    <input id="movieMode"
                           v-model="movieMode"
                           type="checkbox"
                           name="movieMode"
                           class="switch is-rounded is-warning"
                           :class="{ 'is-outlined': !movieMode }" />
                    <label for="movieMode">
                        Movie mode
                        <span v-if="movieMode">&nbsp;ON</span>
                        <span v-else>&nbsp;OFF (click to advance)</span>
                    </label>
                </div>
                <div class="field">
                    <input id="scanlines"
                           v-model="scanlines"
                           type="checkbox"
                           name="scanlines"
                           class="switch is-rounded is-warning"
                           :class="{ 'is-outlined': !scanlines }" />
                    <label for="scanlines">
                        Scanlines
                        <span v-if="scanlines">&nbsp;ON</span>
                        <span v-else>&nbsp;OFF</span>
                    </label>
                </div>
                <div class="field">
                    <input id="contrastMode"
                           v-model="contrastMode"
                           type="checkbox"
                           name="contrastMode"
                           class="switch is-rounded is-warning"
                           :class="{ 'is-outlined': !contrastMode }" />
                    <label for="contrastMode">
                        1-bit mode
                        <span v-if="contrastMode">&nbsp;ON (high contrast)</span>
                        <span v-else>&nbsp;OFF</span>
                    </label>
                </div>
            </section>

            <footer class="modal-card-foot has-options">
                <div class="buttons">
                    <button @click="close"
                            class="button is-dark">OK</button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// TODO: Trap tab focus to modal
// see https://hidde.blog/using-javascript-to-trap-focus-in-an-element/

export default {
    name: "ModalOptions",
    components: {},
    data() {
        return {
            // https://retrogamecoders.com/commodore64-basic-programming/
            code: 'READY.\nLOAD "Options",8,1\nLIST',
            codeNum: 1,
        };
    },

    mounted() {
        this.codeReadingSpeed();
        this.codeScanlines();
    },
    computed: {
        ...mapGetters(["episodeNumber", "currentPanel"]),

        scanlines: {
            get() {
                return this.$store.state.showScanlines;
            },
            set(value) {
                localStorage.scanlines = value;
                this.$store.commit("setScanlines", value);
                this.codeScanlines();
            },
        },
        contrastMode: {
            get() {
                return this.$store.state.contrastMode;
            },
            set(value) {
                localStorage.contrastMode = value;
                this.$store.commit("setContrastMode", value);
                this.codeContrastMode();
            },
        },
        movieMode: {
            get() {
                return this.$store.state.movieMode;
            },
            set(value) {
                localStorage.movieMode = value;
                this.$store.commit("setMovieMode", value);
                this.codeMovieMode();
            },
        },
        readingSpeed: {
            get() {
                return this.$store.state.readingSpeed;
            },
            set(value) {
                localStorage.readingSpeed = value;
                this.$store.commit("setReadingSpeed", value);
            },
        },
        readingFeedback() {
            let feedback = "";
            const speed = parseInt(this.readingSpeed);
            if (speed >= 0) feedback = "Speed reader";
            if (speed >= 5) feedback = "Quite fast";
            if (speed >= 8) feedback = "Fast";
            if (speed >= 15) feedback = "Medium";
            if (speed >= 20) feedback = "Slow";
            if (speed >= 25) feedback = "Nice & slow";
            return feedback;
        },
        routeName() {
            return this.$route.name;
        },
    },
    watch: {},
    methods: {
        ...mapMutations(["closeModal"]),
        close() {
            // if (this.routeName == "Read") {
            //     const num = this.episodeNumber ? this.episodeNumber : 0;
            //     const panel = this.currentPanel ? this.currentPanel - 1 : 0;
            //     this.$store.dispatch("loadEpisode", { episode: num, panel: panel });
            //     this.$router.push({
            //         name: "Read",
            //         query: {
            //             episode: num + "." + panel,
            //         },
            //     });
            // }
            this.closeModal();
        },
        // updateScanlines(e) {
        //     this.setScanlines(e.target.value);
        // },
        codeScanlines() {
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += "INPUT ShowScanLines$";
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += 'PRINT "Show scanlines:" ' + this.scanlines;
        },
        codeContrastMode() {
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += "INPUT HighContrast$";
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += 'PRINT "High-contrast mode:" ' + this.contrastMode;
        },
        codeMovieMode() {
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += "INPUT MovieMode$";
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += 'PRINT "Movie mode:" ' + this.movieMode;
        },
        codeReadingSpeed() {
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += "INPUT ReadingSpeed$";
            this.code += "\n" + this.codeNum++ * 10 + " ";
            this.code += 'PRINT "Reading Speed: ' + this.readingSpeed;
            this.code += " (" + this.readingFeedback.toUpperCase() + ')"';
        },
        makeCode() {
            const length = 10000;
            let text = "";
            const possible =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz   _-+=.?0123456789";

            for (var i = 0; i < length; i++)
                text += possible.charAt(
                    Math.floor(Math.random() * possible.length)
                );

            return text;
        },
    },
};
</script>