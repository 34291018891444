<template>
    <div id="skip">
        <a
            href="#main-content"
            ref="skipLink">Skip to main content</a>
    </div>
</template>

<script>
export default {
    // watch: {
    //     $route() {
    //         this.$refs.skipLink.focus();
    //     }
    // }
};
</script>

<style lang="scss" scoped>
#skip a {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    background-color: white;
    padding: 0.5rem;
}

#skip a:focus
{
    position:fixed;
    left: 8px;
    top: 60px;
    width:auto;
    height:auto;
}
</style>
